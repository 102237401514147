import React from 'react';
import SectionLayout from '../../../layouts/SectionLayout';
import insightsImg from '../../../images/cai_insights_reports_transparent.png';
import SEO from '../../../components/SEO';

export default function SoftwareInsights() {
  return (
    <>
      <SEO />
      <SectionLayout>
        <p className="section-display-subheader">
          SIMPLE SOFTWARE. TRUSTED ANSWERS. IMPROVED RESULTS.
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              Each year, millions of dollars may be spent on seed, nutrients,
              and chemicals, but without the right tools, there’s no way to
              measure the performance of these products. Our insights module
              provides advanced analytics on the most complex data, empowering
              you to make informed decisions based on our unbiased insights.
            </p>
          </div>
        </div>
        <div className="row mobile-desktop-img-margin">
          <div
            className="col-sm-12 text-center"
            style={{
              maxWidth: '1000px',
              margin: '0 auto',
            }}
          >
            <img
              className="img-fluid"
              alt="example report insights"
              src={insightsImg}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
